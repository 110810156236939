@media (max-width: 991px) {
    .promo-cards {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .promo-card {
        position: relative;
        width: 100%;
        height: 46.72897vw !important;
    }
    .promo {
        height: auto !important;
    }
    .promo-cards.first-active .promo-card--first {
        height: 93.45794vw;
    }
    .promo-card--active {
        height: 93.45794vw !important;
    }
    .right-block,
    .left-block {
        display: none;
    }
    .header {
        height: 50px;
        width: 100%;
        padding: 10px;
        z-index: 222222;
    }
    .logo {
        width: 13.271vw;
        height: 7.885vw;
        position: absolute;
        left: 12px;
        top: 9px;
    }
    .lang {
        display: none;
    }
    .burger {
        display: block;
        width: 40px;
        margin-left: auto;
        border: none;
        span {
            width: 10.344vw;
        }
    }
    .burger.active span:nth-child(1) {
        top: 3.73832vw;
    }
    .page {
        padding: 16.35514vw 3.50467vw;
    }
    .promo-card__title {
        font-size: 5.604vw;
        line-height: 8.177vw;
    }
    .promo-card--active .promo-card__title {
        font-size: 6.125vw;
        line-height: 9.115vw;
    }
    .promo-card__descr {
        font-size: 3.4vw;
        line-height: 5.667vw;
        max-width: 81.25vw;
    }
    .promo-card__form {
        font-size: 29.417vw;
        line-height: 26.417vw;
    }
    .promo-card {
        padding: 3.865vw;
    }
    .promo-card__link {
        width: 27.375vw;
        height: 27.375vw;
        background-size: 7.083vw;
    }
    .promo-card__form span {
        white-space: nowrap;
    }
    .promo-bottom {
        grid-template-columns: repeat(2, 1fr);
        margin-top: -0.998px;
    }
    .promo-bottom-item {
        height: 26.021vw;
        border: 0.01vw solid #4c4c4c !important;
        font-weight: 400;
        font-size: 3.563vw;
        line-height: 6.927vw;
    }
    .section {
        margin-bottom: 18.69159vw;
    }
    .title-sec {
        font-size: 5.604vw;
        line-height: 9.177vw;
    }
    .grid-sec__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 5.344vw;
    }
    .grid-sec-item {
        width: 100%;
        font-size: 4.4vw;
        line-height: 7.563vw;
        min-height: 36.802vw;
        height: inherit;
        padding: 5vw !important;
        border-bottom: none;
        &:last-child {
            border-bottom: 0.052vw solid #4c4c4c;
        }
    }
    .bottom-sec__wrap {
        height: auto;
        padding-top: 0;
        overflow: visible;
    }
    .bottom-sec__row.col-2 {
        grid-template-columns: 1fr;
        display: flex;
        flex-direction: column-reverse;
    }
    .bottom-sec__row > div.col {
        flex-direction: column;
    }
    .fz50 {
        font-size: 5.604vw;
        line-height: 7.823vw;
    }
    .fz20 {
        font-size: 3.042vw;
        line-height: 4.823vw;
    }
    .bottom-sec__row > div {
        padding: 5vw;
    }
    .anim-row {
        height: 17.3vw;
    }
    .bottom-sec__row a {
        font-size: 3vw;
        line-height: 8.823vw;
    }
    .bottom-sec__row {
        font-size: 3.198vw;
        line-height: 3.823vw;
    }
    .bottom-sec__row > div.pd-small {
        padding: 4.563vw 5vw;
    }
    .contact__row.col-2 {
        grid-template-columns: 1fr;
    }
    .map {
        height: 70.09346vw;
        margin-bottom: 4.6729vw;
    }
    .line-anim.line-anim--horison {
        width: 100%;
    }
    .contact__row .line-anim {
        top: 0px;
    }
    .contact__row h2 {
        font-size: 6.604vw;
        line-height: 12.177vw;
    }
    .contact__row .contact__info div {
        height: auto;
        padding: 5.042vw 5.083vw;
    }
    .contact__row .fz19 {
        font-size: 2.99vw;
        line-height: 5.667vw;
    }
    .bottom-sec--first li {
        padding-left: 4.1vw;
    }
    .bottom-sec__row {
        line-height: 6.823vw;
    }
    .bottom-sec--first li::before {
        top: 2.6vw;
        width: 1.41667vw;
        height: 1.41667vw;
    }
    .bottom-sec--first .anim-row {
        height: 20vw !important;
        div {
            font-size: 40px;
        }
    }
    .bottom-sec--first .bottom-sec__row.col-2 .col {
        height: 20vw !important;
    }
    .contact__row .fz28 {
        font-size: 3.51vw;
        line-height: 5vw;
    }
    .contact__row.col-3 {
        grid-template-columns: 1fr;
    }
    .contact__row--bottom .contact__info div {
        justify-content: flex-start;
        text-align: left;
    }
    .contact__row--bottom .contact__info.pd-not div {
        padding: 5.042vw 5.083vw;
    }
    .contact__row a {
        font-size: 4.458vw;
        line-height: 4.667vw;
        padding-left: 1.16822vw;
    }
    .contact {
        margin-bottom: 0;
    }
    .contact__row--bottom .contact__info {
        border-right: none;
    }
    .bottom-sec__row a::after {
        width: 2.729vw;
        height: 2.729vw;
        margin-left: 1.365vw;
    }
    .category__top {
        grid-template-columns: 1fr;
    }
    .category__text h2 {
        font-size: 6.646vw;
        line-height: 8.427vw;
    }
    .category__text {
        font-size: 2.885vw;
        line-height: 6.458vw;
        padding: 8.208vw;
        div {
            padding-right: 3.781vw;
        }
        p {
            margin-top: 5.042vw;
        }
    }
    .category__img {
        height: 72.896vw;
    }
    .category__link {
        font-size: 2.885vw;
        line-height: 4.458vw;
        padding: 4.604vw;
    }
    .nav {
        display: none;
    }
    .nav-modal {
        .nav {
            display: block;
        }
    }
    .nav-modal .contact__row.col-3 {
        width: 100%;
    }
    .nav-modal .nav ul li a {
        font-size: 9.125vw;
        line-height: 16.20833vw;
    }
    .bottom-sec--first li {
        font-size: 3vw;
        line-height: 7vw;
    }
    .fz70 {
        font-size: 7vw;
        line-height: 6vw;
    }
    .pd-not--img {
        grid-column: 1 / 2;
    }
}

@media (max-height: 700px) {
    .title-sec {
        font-size: 2vw;
        line-height: 2.8vw;
    }
    .grid-sec-item {
        height: 11.802vw;
    }
    .bottom-sec__wrap {
        height: calc(100vh - 9.5vw);
        padding-bottom: 5vw;
    }
}
