.bottom-sec {
    padding-top: 3.125vw;
}
.section {
}
.bottom-sec__row {
    display: grid;
    &.col-2 {
        grid-template-columns: repeat(2, 1fr);
    }
    &.col-1 {
        grid-template-columns: 1fr;
        font-weight: 400;
        font-size: 4vw;
        line-height: 4vw;
        text-transform: uppercase;
        color: #a8a8a8;
        white-space: nowrap;
    }
    font-weight: 400;
    font-size: 1vw;
    line-height: 1.7vw;
    text-transform: uppercase;
    > div {
        padding: 1.2vw;
        border: 0.052vw solid #4c4c4c;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 180px;
        }
        &.col {
            display: flex;
            gap: 3.208vw;
            justify-content: center;
        }
        &.pd-not {
            padding: 0;
        }
        &.pd-small {
            padding: 1vw 1.2vw;
            &:nth-child(7) {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }
            &:nth-child(6) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }
        }
    }
    a {
        font-weight: 400;
        font-size: 0.885vw;
        line-height: 1.823vw;
        text-transform: uppercase;
        color: #a8a8a8;
        display: block;
        margin-top: 1.563vw;
        position: relative;
        width: fit-content;
        display: flex;
        align-items: center;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 0.052vw;
            background: #a8a8a8;
            left: 0;
            bottom: 0;
        }
        &::after {
            content: "";
            background-image: url("../img/arrow.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 0.729vw;
            height: 0.729vw;
            margin-left: 0.365vw;
        }
        &:hover {
            text-shadow: 0.026vw 0 0 currentColor;
        }
    }
    p {
        margin-top: 0.521vw;
    }
}
.al-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.more-row {
    .fz50 {
        grid-row: 3 / 7;
    }
}
.fz50 {
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 2vw;
    text-transform: uppercase;
}
.fz20 {
    font-weight: 400;
    font-size: 0.8vw;
    line-height: 0.8vw;
    text-transform: uppercase;
}
.fz70 {
    font-weight: 400;
    font-size: 2vw;
    line-height: 3vw;
    text-transform: uppercase;
}
.bottom-sec__wrap {
    height: calc(100vh - 5vw);
    overflow: hidden;
    padding-top: 2vw;
}

.anim-row {
    display: flex;
    gap: 2.083vw;
    white-space: nowrap;
    position: relative;
    height: 6vw;
    border-bottom: 0.052vw solid #4c4c4c;
    overflow: hidden;
    div {
        position: absolute;
        border: none;
        margin-right: 2.083vw;
        padding: 1.042vw;
    }
}

.pd-not--img {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
}

.bottom-sec--first {
    .bottom-sec__row.col-1 {
        margin-top: 1.04167vw;

        line-height: 96px;
        .col {
            padding: 19.2px;
            height: 7.8125vw;
        }
    }
    .bottom-sec__row {
        text-transform: none;
        &:last-child {
            img {
                height: 100% !important;
                max-height: 11.97917vw;
            }
        }
    }
    .anim-row {
        height: 7.8125vw !important;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        font-size: 96px;
        border-bottom: none;
        div {
            top: 27%;
        }
    }
    .bottom-sec__row.col-2 {
        .col {
            overflow: hidden;
            position: relative;
            img {
                height: 7.8125vw;
            }
        }
    }
    ul {
        margin-top: 0.52083vw;
    }
    li {
        padding-left: 1.1vw;
        font-size: 1vw;
        position: relative;
        margin-bottom: 0.52083vw;
        &:last-child {
            margin-bottom: 0;
        }
        &::before {
            position: absolute;
            content: "";
            width: 0.41667vw;
            height: 0.41667vw;
            border-radius: 50%;
            background-color: #fff;
            left: 0.26042vw;
            top: 0.7vw;
        }
    }
}
