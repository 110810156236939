.promo {
    height: calc(100vh - 6.25vw) !important;
    margin-top: 0 !important;
    .fp-tableCell {
        height: 100% !important;
    }
}
.section {
}
.promo-cards {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 8.125vw);
    position: relative;
    &.pointer-not {
        pointer-events: none;
    }
    .promo-card--first {
        .promo-card__form {
            text-align: left;
        }
    }
    &.first-active {
        .promo-card--first {
            z-index: 22222;
            top: 0;
            height: 100%;
            border-right: 0.052vw solid #4c4c4c;
        }
        .promo-card--two {
            top: 0;
            right: 0;
        }
        .promo-card--three {
            top: 50%;
            right: 0;
            border-top: 0.052vw solid #4c4c4c;
        }
    }
    &.two-active {
        .promo-card--first {
            top: 0;
            height: 50%;
        }
        .promo-card--two {
            top: 0;
            right: 0;
            height: 100%;
            border-left: 0.052vw solid #4c4c4c;
            z-index: 22222;
        }
        .promo-card--three {
            top: 50%;
            right: 50%;
            border-top: 0.052vw solid #4c4c4c;
        }
    }
    &.three-active {
        .promo-card--first {
            top: 50%;
            height: 50%;
        }
        .promo-card--two {
            top: 0;
            right: 50%;
            height: 50%;
            border-bottom: 0.052vw solid #4c4c4c;
        }
        .promo-card--three {
            top: 0;
            right: 0;
            height: 100%;
            border-left: 0.052vw solid #4c4c4c;
            z-index: 22222;
        }
    }
}
.promo-card {
    height: 50%;
    width: 50%;
    position: absolute;
    z-index: 1;
    padding: 2.865vw;
    overflow: hidden;
    cursor: pointer;
    transition: all 1s ease-in-out;
    background-color: #4c4c4c;
    border: 0.052vw solid #4c4c4c !important;
}
.promo-card--active {
    height: 100%;

    .promo-card__title {
        font-size: 3.125vw;
        line-height: 4.115vw;
    }
    .promo-card__hide {
        opacity: 1;
    }
    .promo-card__link {
        opacity: 1;
    }
}

.promo-card__hide {
    transition: all 1s ease-in-out;
}

.promo-card__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.promo-card__title {
    font-weight: 400;
    font-size: 2.604vw;
    line-height: 3.177vw;
    text-transform: uppercase;
    color: #ffffff;
    padding-bottom: 1.667vw;
    margin-bottom: 1.406vw;
    border-bottom: 0.052vw solid #a8a8a8;
    transition: all 1s ease-in-out;
}
.promo-card__descr {
    font-weight: 400;
    font-size: 0.99vw;
    line-height: 1.667vw;
    color: #ffffff;
    max-width: 31.25vw;
}
.promo-card__form {
    font-weight: 700;
    font-size: 10.417vw;
    line-height: 10.417vw;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.15;
    position: absolute;
    bottom: 0.781vw;
    left: 2.865vw;
    width: calc(100% - 2.865vw);
    text-align: right;
    padding-right: 4.167vw;
    span {
        display: block;
        &:first-child {
            transform: translateX(2.604vw);
        }
    }
}

.promo-card__hide {
    opacity: 0;
}

.promo-card__link {
    width: 9.375vw;
    height: 9.375vw;
    position: absolute;
    background-image: url("../img/promo-card__link.svg");
    background-repeat: no-repeat;
    background-size: 2.083vw;
    background-position: 75% 75%;
    background-color: #070707;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
    right: 0;
    bottom: 0;
    transition: opacity 1s ease-in-out, background-color 0.3s ease-in-out;
    opacity: 0;
    &:hover {
        background-color: #434343;
    }
    &::before {
        position: absolute;
        content: "";
        width: 14.271vw;
        background-color: #4c4c4c;
        height: 0.052vw;
        transform: rotate(-45deg);
        top: 4.271vw;
        left: -2.031vw;
    }
}

.promo-bottom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top: 0.052vw solid #4c4c4c;
    border-bottom: 0.052vw solid #4c4c4c;
}
.promo-bottom-item {
    height: 8.021vw;
    position: relative;
    border-right: 0.052vw solid #4c4c4c;
    font-weight: 400;
    font-size: 1.563vw;
    line-height: 1.927vw;
    text-transform: uppercase;
    color: #ffffff;
    padding: 2.083vw 2.344vw;
    overflow: hidden;
    &:hover {
        img {
            transform: scale(1.1);
            filter: brightness(2);
        }
    }
    &:last-child {
        border-right: 0;
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: -1;
        display: block;
        transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    }
}
