.contact {
    .fp-tableCell {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 6.25vw;
    }
}
.section {
}
.contact__row {
    display: grid;
    position: relative;
    .line-anim {
        top: -3px;
    }
    &:first-child {
        border-top: 0.052vw solid #4c4c4c;
    }
    &:last-child {
        border-top: 0.052vw solid #4c4c4c;
    }
    h2 {
        font-weight: 400;
        font-size: 2.604vw;
        line-height: 3.177vw;
        text-transform: uppercase;
        color: #ffffff;
        padding: 2.604vw 2.083vw;
        border-bottom: 0.052vw solid #4c4c4c;
    }
    &.col-2 {
        grid-template-columns: repeat(2, 1fr);
    }
    .map {
        #map {
            width: 100%;
            height: 100%;
        }
    }
    .contact__info {
        div {
            padding: 1.042vw 2.083vw;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 5.478vw;
            border-bottom: 0.052vw solid #4c4c4c;
        }
    }

    .fz19 {
        font-weight: 400;
        font-size: 0.99vw;
        line-height: 1.667vw;
        display: block !important;
    }
    .fz28 {
        font-weight: 400;
        font-size: 1.51vw;
        line-height: 1.667vw;
        display: block !important;
    }
    &.col-3 {
        grid-template-columns: repeat(3, 1fr);
    }
    a {
        font-weight: 400;
        font-size: 1.458vw;
        line-height: 1.667vw;
        text-align: center;
        text-decoration-line: underline;
        color: #ffffff;
    }
    .center {
        display: flex !important;
        align-items: center;
        justify-content: flex-start !important;
    }
}

.contact__row--bottom .contact__info div {
    padding: 2.604vw 1.302vw;
    height: 8.073vw;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact__row--bottom .contact__info {
    border-right: 0.052vw solid #4c4c4c;
    &:last-child {
        border: none;
    }
    &.pd-not {
        div {
            padding: 1.563vw 1.302vw;
        }
    }
}
