.grid-sec {
    .fp-tableCell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 6.25vw;
    }
}
.section {
}

.grid-sec__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2.344vw;
    margin-left: -0.052vw;
    margin-right: -0.052vw;
    position: relative;
}
.grid-sec-item {
    width: 33.33%;
    padding: 2.604vw;
    padding-left: 3.385vw;
    padding-bottom: 2.865vw;
    position: relative;
    height: 13.802vw;
    border: 0.052vw solid #4c4c4c;
    font-weight: 400;
    font-size: 1.302vw;
    line-height: 1.563vw;
    text-transform: uppercase;
    color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    &:hover {
        img {
            transform: scale(1.05);
            filter: brightness(2.5);
        }
    }
    img {
        width: calc(100% - 5.208vw);
        height: calc(100% - 5.208vw);
        left: 2.604vw;
        top: 2.604vw;
        object-fit: cover;
        position: absolute;
        z-index: -1;
        transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    }
}
