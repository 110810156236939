* {
    font-family: "Montserrat", sans-serif;
    cursor: none !important;
}

body {
    background-color: #121212;
    color: #ffffff;
    &.scroll-hide {
        overflow: hidden;
    }
}

.hide-elem {
    transition: all 0.3s ease-in-out;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
}

.container {
    max-width: 58.854vw;
    margin: 0 auto;
    padding: 0 0.781vw;
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    padding: 6.25vw 7.292vw 0;
}

.page-not-main {
    .left-block__text {
        display: none;
    }
    .left-block::after {
        display: none;
    }
    .left-block span {
        display: none;
    }
    .line-anim {
        display: block !important;
    }
}

.iScrollIndicator {
    display: none !important;
}

.title-sec {
    font-weight: 400;
    font-size: 2.604vw;
    line-height: 3.177vw;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 2.865vw;
}

.section {
    position: relative;
    &.active {
    }
}

.sec-bg {
    position: absolute;
    width: calc(100% + 14.583vw);
    height: calc(100vh - 6.25vw);
    left: -7.292vw;
    bottom: 6.25vw;
    z-index: -1;
    object-fit: cover;
    display: block;
}

.line-anim {
    position: absolute;
    &.line-anim--horison {
        width: calc(100% + 10.417vw);
        height: 0.156vw;
        bottom: -0.052vw;
        left: 0;
        overflow: hidden;

        &::before {
            position: absolute;
            content: "";
            width: 3.125vw;
            height: 0.156vw;
            background: #ffffff;
            animation: anim-line-horison infinite 3s
                cubic-bezier(0.77, 0, 0.175, 1);
            z-index: 2;
        }

        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 0.052vw;
            left: 0;
            background-color: rgba(207, 217, 236, 0.35);
            top: 0.052vw;
        }
    }

    &.line-anim--vertical {
        height: 100%;
        width: 0.156vw;
        right: -0.104vw;
        top: 0;
        overflow: hidden;
        background-color: transparent;
        &::before {
            position: absolute;
            content: "";
            height: 3.125vw;
            width: 0.156vw;
            bottom: 0;
            background: #ffffff;
            animation: anim-line-vertical infinite 3s
                cubic-bezier(0.77, 0, 0.175, 1);
        }
    }
    &.top-position {
        bottom: inherit;
        top: 0;
    }
    &.left-position {
        left: 0;
        right: inherit;
    }
}

@keyframes anim-line-horison {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(1920px);
    }
}

@keyframes anim-line-vertical {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100vh);
    }
}

.dot {
    position: fixed;
    pointer-events: none;
    width: 1.302vw;
    height: 1.302vw;
    z-index: 222;
    display: flex;
    transform: translate(-0.651vw, -0.651vw);
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: #d9d9d9;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &::after {
        position: absolute;
        content: "";
        width: 2.656vw;
        height: 2.656vw;
        border: 0.052vw solid #d9d9d9;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    span {
        position: absolute;
        content: "";
        background: #d9d9d9;
        opacity: 0.2;
        filter: blur(3.906vw);
        width: 14.948vw;
        height: 14.948vw;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.bg {
    background: url("../img/noise.gif") repeat top left;
    opacity: 0.07;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
