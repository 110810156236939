.burger {
    height: 8.125vw;
    min-height: 8.125vw;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 3522220;
    cursor: pointer;
    transition: all 0.3s;
    display: none;
    position: relative;
    border-top: 0.052vw solid #4c4c4c;
    margin-top: auto;

    &:hover {
        span {
            &:nth-child(1) {
                transform: translateY(-0.26vw);
            }

            &:nth-child(3) {
                transform: translateY(0.26vw);
            }
        }
    }
    span {
        position: absolute;
        background: #fff;
        height: 0.104vw;
        width: 2.344vw;
        display: block;
        transition: all 0.3s;
        border-radius: 0.26vw;

        &:nth-child(1) {
            top: 3.021vw;
        }

        &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(3) {
            bottom: 3.021vw;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                top: 4.01042vw;
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                bottom: 3.90625vw;
                transform: rotate(-45deg);
            }
        }
    }
}
