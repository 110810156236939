.category {
}
.category__top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    position: relative;
}
.category__img {
    height: 34.896vw;
    width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.category__text {
    font-weight: 400;
    font-size: 0.885vw;
    line-height: 1.458vw;
    padding: 5.208vw;
    h2 {
        font-weight: 400;
        font-size: 3.646vw;
        line-height: 4.427vw;
        text-transform: uppercase;
        color: #e5e5e5;
    }
    p {
        margin-top: 1.042vw;
    }
    div {
        position: relative;
        padding-right: 0.781vw;
    }
    .line-anim.line-anim--vertical::before {
        height: 40.8px;
    }
    .line-anim {
        background: #4c4c4c;
    }
}
.category__link {
    width: 100%;
    font-weight: 400;
    font-size: 0.885vw;
    line-height: 1.458vw;
    text-align: center;
    text-decoration-line: underline;
    padding: 2.604vw;
    border-top: 0.052vw solid #4c4c4c;
    border-bottom: 0.052vw solid #4c4c4c;
    margin-bottom: 8.854vw;
    a {
        color: #ffffff;
    }
}

.page-not-main {
    padding-bottom: 5.208vw;
}
