:root {
  --color-black: #000;
  --color-white: #fff;
}

/* @include font-face("GothamPro", "GothamPro", 400, normal); */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

a {
  text-decoration: none;
}

body {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Montserrat", sans-serif;
  cursor: none !important;
}

body {
  background-color: #121212;
  color: #ffffff;
}
body.scroll-hide {
  overflow: hidden;
}

.hide-elem {
  transition: all 0.3s ease-in-out;
}
.hide-elem.hide {
  opacity: 0;
  pointer-events: none;
}

.container {
  max-width: 58.854vw;
  margin: 0 auto;
  padding: 0 0.781vw;
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  padding: 6.25vw 7.292vw 0;
}

.page-not-main .left-block__text {
  display: none;
}
.page-not-main .left-block::after {
  display: none;
}
.page-not-main .left-block span {
  display: none;
}
.page-not-main .line-anim {
  display: block !important;
}

.iScrollIndicator {
  display: none !important;
}

.title-sec {
  font-weight: 400;
  font-size: 2.604vw;
  line-height: 3.177vw;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 2.865vw;
}

.section {
  position: relative;
}
.sec-bg {
  position: absolute;
  width: calc(100% + 14.583vw);
  height: calc(100vh - 6.25vw);
  left: -7.292vw;
  bottom: 6.25vw;
  z-index: -1;
  object-fit: cover;
  display: block;
}

.line-anim {
  position: absolute;
}
.line-anim.line-anim--horison {
  width: calc(100% + 10.417vw);
  height: 0.156vw;
  bottom: -0.052vw;
  left: 0;
  overflow: hidden;
}
.line-anim.line-anim--horison::before {
  position: absolute;
  content: "";
  width: 3.125vw;
  height: 0.156vw;
  background: #ffffff;
  animation: anim-line-horison infinite 3s cubic-bezier(0.77, 0, 0.175, 1);
  z-index: 2;
}
.line-anim.line-anim--horison::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0.052vw;
  left: 0;
  background-color: rgba(207, 217, 236, 0.35);
  top: 0.052vw;
}
.line-anim.line-anim--vertical {
  height: 100%;
  width: 0.156vw;
  right: -0.104vw;
  top: 0;
  overflow: hidden;
  background-color: transparent;
}
.line-anim.line-anim--vertical::before {
  position: absolute;
  content: "";
  height: 3.125vw;
  width: 0.156vw;
  bottom: 0;
  background: #ffffff;
  animation: anim-line-vertical infinite 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.line-anim.top-position {
  bottom: inherit;
  top: 0;
}
.line-anim.left-position {
  left: 0;
  right: inherit;
}

@keyframes anim-line-horison {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(1920px);
  }
}
@keyframes anim-line-vertical {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100vh);
  }
}
.dot {
  position: fixed;
  pointer-events: none;
  width: 1.302vw;
  height: 1.302vw;
  z-index: 222;
  display: flex;
  transform: translate(-0.651vw, -0.651vw);
}
.dot::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #d9d9d9;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.dot::after {
  position: absolute;
  content: "";
  width: 2.656vw;
  height: 2.656vw;
  border: 0.052vw solid #d9d9d9;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.dot span {
  position: absolute;
  content: "";
  background: #d9d9d9;
  opacity: 0.2;
  filter: blur(3.906vw);
  width: 14.948vw;
  height: 14.948vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bg {
  background: url("../img/noise.gif") repeat top left;
  opacity: 0.07;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.header {
  position: fixed;
  width: calc(100% - 7.188vw);
  padding: 2.344vw 0;
  padding-left: 6.25vw;
  right: 0;
  top: 0;
  border-bottom: 0.052vw solid #4c4c4c;
  z-index: 22;
  background-color: #121212;
}
.header .line-anim::before {
  animation-delay: 1.5s;
}
.header .bg {
  position: absolute;
}

.logo {
  position: fixed;
  left: 2.083vw;
  top: 2.083vw;
  width: 9.271vw;
  height: 5.885vw;
}
.logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav ul {
  display: flex;
  align-items: center;
}
.nav ul li {
  padding-right: 0.781vw;
  margin-right: 0.781vw;
  border-right: 0.052vw solid #ffffff;
}
.nav ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}
.nav ul a {
  font-weight: 400;
  font-size: 1.146vw;
  line-height: 1.563vw;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
}

.left-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  border: 1px solid #4c4c4c;
  height: calc(100% - 6.25vw);
  width: 7.292vw;
  padding-top: 4vw;
  padding-bottom: 1.042vw;
  border-top: none;
  border-left: none;
  border-bottom: none;
}
.left-block span {
  height: 5vw;
  width: 0.052vw;
  display: block;
  margin: 1.042vw 0;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.left-block::after {
  content: "";
  background-image: url("../img/mouse.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 2.448vw;
  height: 2.448vw;
  transition: all 0.3s ease-in-out;
}
.left-block.hide {
  opacity: 1;
}
.left-block.hide .left-block__text, .left-block.hide::after,
.left-block.hide span {
  opacity: 0;
  pointer-events: none;
}
.left-block.hide .line-anim.line-anim--vertical {
  opacity: 1;
}

.left-block__text {
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 1.563vw;
  text-transform: uppercase;
  color: #ffffff;
  white-space: nowrap;
  writing-mode: vertical-rl;
  width: fit-content;
}

.right-block {
  border-left: 0.052vw solid #4c4c4c;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 7.292vw;
  z-index: 26661;
}
.right-block .burger {
  display: flex;
}
.right-block .line-anim::before {
  animation-delay: 0.7s;
}

.lang {
  font-weight: 700;
  font-size: 1.146vw;
  line-height: 1.563vw;
  text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  right: 1.563vw;
  top: 2.5vw;
  z-index: 25;
}
.lang span {
  padding-right: 0.26vw;
}
.lang a {
  color: #ffffff;
  font-weight: 400;
  padding-left: 0.26vw;
}

.right-block__anim {
  margin-top: 6.25vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.right-block__inner {
  width: fit-content;
  font-weight: 400;
  font-size: 7.031vw;
  line-height: 7.552vw;
  text-transform: uppercase;
  font-family: "Inter";
  color: #a8a8a8;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  height: 100%;
}
.right-block__inner.hide {
  opacity: 0 !important;
}
.right-block__inner.active {
  opacity: 1;
}
.right-block__inner span {
  display: block;
  white-space: nowrap;
  writing-mode: vertical-rl;
  margin-bottom: 2.083vw;
}

.nav-modal {
  backdrop-filter: blur(0.26042vw);
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  pointer-events: none;
  transform: scale(0.9);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 22222222222222;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.nav-modal.active {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
}
.nav-modal .nav {
  height: calc(100% - 8.07292vw);
}
.nav-modal .nav ul {
  flex-direction: column;
  justify-content: center;
  gap: 0.72917vw;
  padding: 2.60417vw;
  height: 100%;
}
.nav-modal .nav ul li {
  padding-right: 0;
  margin-right: 0;
  border-right: 0;
}
.nav-modal .nav ul li a {
  font-size: 3.125vw;
  line-height: 5.20833vw;
}
.nav-modal .contact__row.col-3 {
  width: calc(100% - 7.29167vw);
  margin-bottom: -0.05208vw;
}

.promo {
  height: calc(100vh - 6.25vw) !important;
  margin-top: 0 !important;
}
.promo .fp-tableCell {
  height: 100% !important;
}

.promo-cards {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 8.125vw);
  position: relative;
}
.promo-cards.pointer-not {
  pointer-events: none;
}
.promo-cards .promo-card--first .promo-card__form {
  text-align: left;
}
.promo-cards.first-active .promo-card--first {
  z-index: 22222;
  top: 0;
  height: 100%;
  border-right: 0.052vw solid #4c4c4c;
}
.promo-cards.first-active .promo-card--two {
  top: 0;
  right: 0;
}
.promo-cards.first-active .promo-card--three {
  top: 50%;
  right: 0;
  border-top: 0.052vw solid #4c4c4c;
}
.promo-cards.two-active .promo-card--first {
  top: 0;
  height: 50%;
}
.promo-cards.two-active .promo-card--two {
  top: 0;
  right: 0;
  height: 100%;
  border-left: 0.052vw solid #4c4c4c;
  z-index: 22222;
}
.promo-cards.two-active .promo-card--three {
  top: 50%;
  right: 50%;
  border-top: 0.052vw solid #4c4c4c;
}
.promo-cards.three-active .promo-card--first {
  top: 50%;
  height: 50%;
}
.promo-cards.three-active .promo-card--two {
  top: 0;
  right: 50%;
  height: 50%;
  border-bottom: 0.052vw solid #4c4c4c;
}
.promo-cards.three-active .promo-card--three {
  top: 0;
  right: 0;
  height: 100%;
  border-left: 0.052vw solid #4c4c4c;
  z-index: 22222;
}

.promo-card {
  height: 50%;
  width: 50%;
  position: absolute;
  z-index: 1;
  padding: 2.865vw;
  overflow: hidden;
  cursor: pointer;
  transition: all 1s ease-in-out;
  background-color: #4c4c4c;
  border: 0.052vw solid #4c4c4c !important;
}

.promo-card--active {
  height: 100%;
}
.promo-card--active .promo-card__title {
  font-size: 3.125vw;
  line-height: 4.115vw;
}
.promo-card--active .promo-card__hide {
  opacity: 1;
}
.promo-card--active .promo-card__link {
  opacity: 1;
}

.promo-card__hide {
  transition: all 1s ease-in-out;
}

.promo-card__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.promo-card__title {
  font-weight: 400;
  font-size: 2.604vw;
  line-height: 3.177vw;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 1.667vw;
  margin-bottom: 1.406vw;
  border-bottom: 0.052vw solid #a8a8a8;
  transition: all 1s ease-in-out;
}

.promo-card__descr {
  font-weight: 400;
  font-size: 0.99vw;
  line-height: 1.667vw;
  color: #ffffff;
  max-width: 31.25vw;
}

.promo-card__form {
  font-weight: 700;
  font-size: 10.417vw;
  line-height: 10.417vw;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.15;
  position: absolute;
  bottom: 0.781vw;
  left: 2.865vw;
  width: calc(100% - 2.865vw);
  text-align: right;
  padding-right: 4.167vw;
}
.promo-card__form span {
  display: block;
}
.promo-card__form span:first-child {
  transform: translateX(2.604vw);
}

.promo-card__hide {
  opacity: 0;
}

.promo-card__link {
  width: 9.375vw;
  height: 9.375vw;
  position: absolute;
  background-image: url("../img/promo-card__link.svg");
  background-repeat: no-repeat;
  background-size: 2.083vw;
  background-position: 75% 75%;
  background-color: #070707;
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  right: 0;
  bottom: 0;
  transition: opacity 1s ease-in-out, background-color 0.3s ease-in-out;
  opacity: 0;
}
.promo-card__link:hover {
  background-color: #434343;
}
.promo-card__link::before {
  position: absolute;
  content: "";
  width: 14.271vw;
  background-color: #4c4c4c;
  height: 0.052vw;
  transform: rotate(-45deg);
  top: 4.271vw;
  left: -2.031vw;
}

.promo-bottom {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 0.052vw solid #4c4c4c;
  border-bottom: 0.052vw solid #4c4c4c;
}

.promo-bottom-item {
  height: 8.021vw;
  position: relative;
  border-right: 0.052vw solid #4c4c4c;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 1.927vw;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2.083vw 2.344vw;
  overflow: hidden;
}
.promo-bottom-item:hover img {
  transform: scale(1.1);
  filter: brightness(2);
}
.promo-bottom-item:last-child {
  border-right: 0;
}
.promo-bottom-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
  display: block;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.burger {
  height: 8.125vw;
  min-height: 8.125vw;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 3522220;
  cursor: pointer;
  transition: all 0.3s;
  display: none;
  position: relative;
  border-top: 0.052vw solid #4c4c4c;
  margin-top: auto;
}
.burger:hover span:nth-child(1) {
  transform: translateY(-0.26vw);
}
.burger:hover span:nth-child(3) {
  transform: translateY(0.26vw);
}
.burger span {
  position: absolute;
  background: #fff;
  height: 0.104vw;
  width: 2.344vw;
  display: block;
  transition: all 0.3s;
  border-radius: 0.26vw;
}
.burger span:nth-child(1) {
  top: 3.021vw;
}
.burger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger span:nth-child(3) {
  bottom: 3.021vw;
}
.burger.active span:nth-child(1) {
  top: 4.01042vw;
  transform: rotate(45deg);
}
.burger.active span:nth-child(2) {
  opacity: 0;
}
.burger.active span:nth-child(3) {
  bottom: 3.90625vw;
  transform: rotate(-45deg);
}

.grid-sec .fp-tableCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 6.25vw;
}

.grid-sec__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2.344vw;
  margin-left: -0.052vw;
  margin-right: -0.052vw;
  position: relative;
}

.grid-sec-item {
  width: 33.33%;
  padding: 2.604vw;
  padding-left: 3.385vw;
  padding-bottom: 2.865vw;
  position: relative;
  height: 13.802vw;
  border: 0.052vw solid #4c4c4c;
  font-weight: 400;
  font-size: 1.302vw;
  line-height: 1.563vw;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}
.grid-sec-item:hover img {
  transform: scale(1.05);
  filter: brightness(2.5);
}
.grid-sec-item img {
  width: calc(100% - 5.208vw);
  height: calc(100% - 5.208vw);
  left: 2.604vw;
  top: 2.604vw;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.bottom-sec {
  padding-top: 3.125vw;
}

.bottom-sec__row {
  display: grid;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1.7vw;
  text-transform: uppercase;
}
.bottom-sec__row.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.bottom-sec__row.col-1 {
  grid-template-columns: 1fr;
  font-weight: 400;
  font-size: 4vw;
  line-height: 4vw;
  text-transform: uppercase;
  color: #a8a8a8;
  white-space: nowrap;
}
.bottom-sec__row > div {
  padding: 1.2vw;
  border: 0.052vw solid #4c4c4c;
}
.bottom-sec__row > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 180px;
}
.bottom-sec__row > div.col {
  display: flex;
  gap: 3.208vw;
  justify-content: center;
}
.bottom-sec__row > div.pd-not {
  padding: 0;
}
.bottom-sec__row > div.pd-small {
  padding: 1vw 1.2vw;
}
.bottom-sec__row > div.pd-small:nth-child(7) {
  grid-column: 1/2;
  grid-row: 1/2;
}
.bottom-sec__row > div.pd-small:nth-child(6) {
  grid-column: 1/2;
  grid-row: 2/3;
}
.bottom-sec__row a {
  font-weight: 400;
  font-size: 0.885vw;
  line-height: 1.823vw;
  text-transform: uppercase;
  color: #a8a8a8;
  display: block;
  margin-top: 1.563vw;
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
}
.bottom-sec__row a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0.052vw;
  background: #a8a8a8;
  left: 0;
  bottom: 0;
}
.bottom-sec__row a::after {
  content: "";
  background-image: url("../img/arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.729vw;
  height: 0.729vw;
  margin-left: 0.365vw;
}
.bottom-sec__row a:hover {
  text-shadow: 0.026vw 0 0 currentColor;
}
.bottom-sec__row p {
  margin-top: 0.521vw;
}

.al-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.more-row .fz50 {
  grid-row: 3/7;
}

.fz50 {
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 2vw;
  text-transform: uppercase;
}

.fz20 {
  font-weight: 400;
  font-size: 0.8vw;
  line-height: 0.8vw;
  text-transform: uppercase;
}

.fz70 {
  font-weight: 400;
  font-size: 2vw;
  line-height: 3vw;
  text-transform: uppercase;
}

.bottom-sec__wrap {
  height: calc(100vh - 5vw);
  overflow: hidden;
  padding-top: 2vw;
}

.anim-row {
  display: flex;
  gap: 2.083vw;
  white-space: nowrap;
  position: relative;
  height: 6vw;
  border-bottom: 0.052vw solid #4c4c4c;
  overflow: hidden;
}
.anim-row div {
  position: absolute;
  border: none;
  margin-right: 2.083vw;
  padding: 1.042vw;
}

.pd-not--img {
  grid-column: 2/3;
  grid-row: 1/3;
}

.bottom-sec--first .bottom-sec__row.col-1 {
  margin-top: 1.04167vw;
  line-height: 96px;
}
.bottom-sec--first .bottom-sec__row.col-1 .col {
  padding: 19.2px;
  height: 7.8125vw;
}
.bottom-sec--first .bottom-sec__row {
  text-transform: none;
}
.bottom-sec--first .bottom-sec__row:last-child img {
  height: 100% !important;
  max-height: 11.97917vw;
}
.bottom-sec--first .anim-row {
  height: 7.8125vw !important;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 96px;
  border-bottom: none;
}
.bottom-sec--first .anim-row div {
  top: 27%;
}
.bottom-sec--first .bottom-sec__row.col-2 .col {
  overflow: hidden;
  position: relative;
}
.bottom-sec--first .bottom-sec__row.col-2 .col img {
  height: 7.8125vw;
}
.bottom-sec--first ul {
  margin-top: 0.52083vw;
}
.bottom-sec--first li {
  padding-left: 1.1vw;
  font-size: 1vw;
  position: relative;
  margin-bottom: 0.52083vw;
}
.bottom-sec--first li:last-child {
  margin-bottom: 0;
}
.bottom-sec--first li::before {
  position: absolute;
  content: "";
  width: 0.41667vw;
  height: 0.41667vw;
  border-radius: 50%;
  background-color: #fff;
  left: 0.26042vw;
  top: 0.7vw;
}

.contact .fp-tableCell {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 6.25vw;
}

.contact__row {
  display: grid;
  position: relative;
}
.contact__row .line-anim {
  top: -3px;
}
.contact__row:first-child {
  border-top: 0.052vw solid #4c4c4c;
}
.contact__row:last-child {
  border-top: 0.052vw solid #4c4c4c;
}
.contact__row h2 {
  font-weight: 400;
  font-size: 2.604vw;
  line-height: 3.177vw;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2.604vw 2.083vw;
  border-bottom: 0.052vw solid #4c4c4c;
}
.contact__row.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.contact__row .map #map {
  width: 100%;
  height: 100%;
}
.contact__row .contact__info div {
  padding: 1.042vw 2.083vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.478vw;
  border-bottom: 0.052vw solid #4c4c4c;
}
.contact__row .fz19 {
  font-weight: 400;
  font-size: 0.99vw;
  line-height: 1.667vw;
  display: block !important;
}
.contact__row .fz28 {
  font-weight: 400;
  font-size: 1.51vw;
  line-height: 1.667vw;
  display: block !important;
}
.contact__row.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.contact__row a {
  font-weight: 400;
  font-size: 1.458vw;
  line-height: 1.667vw;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
}
.contact__row .center {
  display: flex !important;
  align-items: center;
  justify-content: flex-start !important;
}

.contact__row--bottom .contact__info div {
  padding: 2.604vw 1.302vw;
  height: 8.073vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact__row--bottom .contact__info {
  border-right: 0.052vw solid #4c4c4c;
}
.contact__row--bottom .contact__info:last-child {
  border: none;
}
.contact__row--bottom .contact__info.pd-not div {
  padding: 1.563vw 1.302vw;
}

.category__top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  position: relative;
}

.category__img {
  height: 34.896vw;
  width: 100%;
}
.category__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category__text {
  font-weight: 400;
  font-size: 0.885vw;
  line-height: 1.458vw;
  padding: 5.208vw;
}
.category__text h2 {
  font-weight: 400;
  font-size: 3.646vw;
  line-height: 4.427vw;
  text-transform: uppercase;
  color: #e5e5e5;
}
.category__text p {
  margin-top: 1.042vw;
}
.category__text div {
  position: relative;
  padding-right: 0.781vw;
}
.category__text .line-anim.line-anim--vertical::before {
  height: 40.8px;
}
.category__text .line-anim {
  background: #4c4c4c;
}

.category__link {
  width: 100%;
  font-weight: 400;
  font-size: 0.885vw;
  line-height: 1.458vw;
  text-align: center;
  text-decoration-line: underline;
  padding: 2.604vw;
  border-top: 0.052vw solid #4c4c4c;
  border-bottom: 0.052vw solid #4c4c4c;
  margin-bottom: 8.854vw;
}
.category__link a {
  color: #ffffff;
}

.page-not-main {
  padding-bottom: 5.208vw;
}

@media (max-width: 991px) {
  .promo-cards {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .promo-card {
    position: relative;
    width: 100%;
    height: 46.72897vw !important;
  }
  .promo {
    height: auto !important;
  }
  .promo-cards.first-active .promo-card--first {
    height: 93.45794vw;
  }
  .promo-card--active {
    height: 93.45794vw !important;
  }
  .right-block,
  .left-block {
    display: none;
  }
  .header {
    height: 50px;
    width: 100%;
    padding: 10px;
    z-index: 222222;
  }
  .logo {
    width: 13.271vw;
    height: 7.885vw;
    position: absolute;
    left: 12px;
    top: 9px;
  }
  .lang {
    display: none;
  }
  .burger {
    display: block;
    width: 40px;
    margin-left: auto;
    border: none;
  }
  .burger span {
    width: 10.344vw;
  }
  .burger.active span:nth-child(1) {
    top: 3.73832vw;
  }
  .page {
    padding: 16.35514vw 3.50467vw;
  }
  .promo-card__title {
    font-size: 5.604vw;
    line-height: 8.177vw;
  }
  .promo-card--active .promo-card__title {
    font-size: 6.125vw;
    line-height: 9.115vw;
  }
  .promo-card__descr {
    font-size: 3.4vw;
    line-height: 5.667vw;
    max-width: 81.25vw;
  }
  .promo-card__form {
    font-size: 29.417vw;
    line-height: 26.417vw;
  }
  .promo-card {
    padding: 3.865vw;
  }
  .promo-card__link {
    width: 27.375vw;
    height: 27.375vw;
    background-size: 7.083vw;
  }
  .promo-card__form span {
    white-space: nowrap;
  }
  .promo-bottom {
    grid-template-columns: repeat(2, 1fr);
    margin-top: -0.998px;
  }
  .promo-bottom-item {
    height: 26.021vw;
    border: 0.01vw solid #4c4c4c !important;
    font-weight: 400;
    font-size: 3.563vw;
    line-height: 6.927vw;
  }
  .section {
    margin-bottom: 18.69159vw;
  }
  .title-sec {
    font-size: 5.604vw;
    line-height: 9.177vw;
  }
  .grid-sec__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 5.344vw;
  }
  .grid-sec-item {
    width: 100%;
    font-size: 4.4vw;
    line-height: 7.563vw;
    min-height: 36.802vw;
    height: inherit;
    padding: 5vw !important;
    border-bottom: none;
  }
  .grid-sec-item:last-child {
    border-bottom: 0.052vw solid #4c4c4c;
  }
  .bottom-sec__wrap {
    height: auto;
    padding-top: 0;
    overflow: visible;
  }
  .bottom-sec__row.col-2 {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column-reverse;
  }
  .bottom-sec__row > div.col {
    flex-direction: column;
  }
  .fz50 {
    font-size: 5.604vw;
    line-height: 7.823vw;
  }
  .fz20 {
    font-size: 3.042vw;
    line-height: 4.823vw;
  }
  .bottom-sec__row > div {
    padding: 5vw;
  }
  .anim-row {
    height: 17.3vw;
  }
  .bottom-sec__row a {
    font-size: 3vw;
    line-height: 8.823vw;
  }
  .bottom-sec__row {
    font-size: 3.198vw;
    line-height: 3.823vw;
  }
  .bottom-sec__row > div.pd-small {
    padding: 4.563vw 5vw;
  }
  .contact__row.col-2 {
    grid-template-columns: 1fr;
  }
  .map {
    height: 70.09346vw;
    margin-bottom: 4.6729vw;
  }
  .line-anim.line-anim--horison {
    width: 100%;
  }
  .contact__row .line-anim {
    top: 0px;
  }
  .contact__row h2 {
    font-size: 6.604vw;
    line-height: 12.177vw;
  }
  .contact__row .contact__info div {
    height: auto;
    padding: 5.042vw 5.083vw;
  }
  .contact__row .fz19 {
    font-size: 2.99vw;
    line-height: 5.667vw;
  }
  .bottom-sec--first li {
    padding-left: 4.1vw;
  }
  .bottom-sec__row {
    line-height: 6.823vw;
  }
  .bottom-sec--first li::before {
    top: 2.6vw;
    width: 1.41667vw;
    height: 1.41667vw;
  }
  .bottom-sec--first .anim-row {
    height: 20vw !important;
  }
  .bottom-sec--first .anim-row div {
    font-size: 40px;
  }
  .bottom-sec--first .bottom-sec__row.col-2 .col {
    height: 20vw !important;
  }
  .contact__row .fz28 {
    font-size: 3.51vw;
    line-height: 5vw;
  }
  .contact__row.col-3 {
    grid-template-columns: 1fr;
  }
  .contact__row--bottom .contact__info div {
    justify-content: flex-start;
    text-align: left;
  }
  .contact__row--bottom .contact__info.pd-not div {
    padding: 5.042vw 5.083vw;
  }
  .contact__row a {
    font-size: 4.458vw;
    line-height: 4.667vw;
    padding-left: 1.16822vw;
  }
  .contact {
    margin-bottom: 0;
  }
  .contact__row--bottom .contact__info {
    border-right: none;
  }
  .bottom-sec__row a::after {
    width: 2.729vw;
    height: 2.729vw;
    margin-left: 1.365vw;
  }
  .category__top {
    grid-template-columns: 1fr;
  }
  .category__text h2 {
    font-size: 6.646vw;
    line-height: 8.427vw;
  }
  .category__text {
    font-size: 2.885vw;
    line-height: 6.458vw;
    padding: 8.208vw;
  }
  .category__text div {
    padding-right: 3.781vw;
  }
  .category__text p {
    margin-top: 5.042vw;
  }
  .category__img {
    height: 72.896vw;
  }
  .category__link {
    font-size: 2.885vw;
    line-height: 4.458vw;
    padding: 4.604vw;
  }
  .nav {
    display: none;
  }
  .nav-modal .nav {
    display: block;
  }
  .nav-modal .contact__row.col-3 {
    width: 100%;
  }
  .nav-modal .nav ul li a {
    font-size: 9.125vw;
    line-height: 16.20833vw;
  }
  .bottom-sec--first li {
    font-size: 3vw;
    line-height: 7vw;
  }
  .fz70 {
    font-size: 7vw;
    line-height: 6vw;
  }
  .pd-not--img {
    grid-column: 1/2;
  }
}
@media (max-height: 700px) {
  .title-sec {
    font-size: 2vw;
    line-height: 2.8vw;
  }
  .grid-sec-item {
    height: 11.802vw;
  }
  .bottom-sec__wrap {
    height: calc(100vh - 9.5vw);
    padding-bottom: 5vw;
  }
}