.header {
    position: fixed;
    width: calc(100% - 7.188vw);
    padding: 2.344vw 0;
    padding-left: 6.25vw;
    right: 0;
    top: 0;
    border-bottom: 0.052vw solid #4c4c4c;
    z-index: 22;
    background-color: #121212;
    .line-anim::before {
        animation-delay: 1.5s;
    }
    .bg {
        position: absolute;
    }
}
.logo {
    position: fixed;
    left: 2.083vw;
    top: 2.083vw;
    width: 9.271vw;
    height: 5.885vw;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.nav {
    ul {
        display: flex;
        align-items: center;
        li {
            padding-right: 0.781vw;
            margin-right: 0.781vw;
            border-right: 0.052vw solid #ffffff;
            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            }
        }
        a {
            font-weight: 400;
            font-size: 1.146vw;
            line-height: 1.563vw;
            text-transform: uppercase;
            color: #ffffff;
            display: block;
        }
    }
}
.left-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    border: 1px solid #4c4c4c;
    height: calc(100% - 6.25vw);
    width: 7.292vw;
    padding-top: 4vw;
    padding-bottom: 1.042vw;
    border-top: none;
    border-left: none;
    border-bottom: none;
    span {
        height: 5vw;
        width: 0.052vw;
        display: block;
        margin: 1.042vw 0;
        background-color: #ffff;
        transition: all 0.3s ease-in-out;
    }
    &::after {
        content: "";
        background-image: url("../img/mouse.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 2.448vw;
        height: 2.448vw;
        transition: all 0.3s ease-in-out;
    }
    &.hide {
        opacity: 1;

        .left-block__text,
        &::after,
        span {
            opacity: 0;
            pointer-events: none;
        }
        .line-anim.line-anim--vertical {
            opacity: 1;
        }
    }
}
.left-block__text {
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 1.563vw;
    text-transform: uppercase;
    color: #ffffff;
    white-space: nowrap;
    writing-mode: vertical-rl;
    width: fit-content;
}
.right-block {
    border-left: 0.052vw solid #4c4c4c;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 7.292vw;
    z-index: 26661;
    .burger {
        display: flex;
    }
    .line-anim::before {
        animation-delay: 0.7s;
    }
}
.lang {
    font-weight: 700;
    font-size: 1.146vw;
    line-height: 1.563vw;
    text-transform: uppercase;
    color: #ffffff;
    position: absolute;
    right: 1.563vw;
    top: 2.5vw;
    z-index: 25;
    span {
        padding-right: 0.26vw;
    }
    a {
        color: #ffffff;
        font-weight: 400;
        padding-left: 0.26vw;
    }
}
.right-block__anim {
    margin-top: 6.25vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.right-block__inner {
    width: fit-content;
    font-weight: 400;
    font-size: 7.031vw;
    line-height: 7.552vw;
    text-transform: uppercase;
    font-family: "Inter";
    color: #a8a8a8;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    height: 100%;
    &.hide {
        opacity: 0 !important;
    }
    &.active {
        opacity: 1;
    }
    span {
        display: block;
        white-space: nowrap;
        writing-mode: vertical-rl;
        margin-bottom: 2.083vw;
    }
}

.nav-modal {
    backdrop-filter: blur(0.26042vw);
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transform: scale(0.9);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 22222222222222;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    &.active {
        background-color: rgba(0, 0, 0, 0.8);
        opacity: 1;
        pointer-events: all;
        transform: scale(1);
    }
    .nav {
        height: calc(100% - 8.07292vw);
    }
    .nav ul {
        flex-direction: column;
        justify-content: center;
        gap: 0.72917vw;
        padding: 2.60417vw;
        height: 100%;
        li {
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
            a {
                font-size: 3.125vw;
                line-height: 5.20833vw;
            }
        }
    }
    .contact__row.col-3 {
        width: calc(100% - 7.29167vw);
        margin-bottom: -0.05208vw;
    }
}
